import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Slide from "../components/slide";
import { Heading, Button, Box } from "@chakra-ui/react";
import RevcontentAd from "../components/ads/revcontent";
import { parseQueryString } from "../utils/url";

function Slideshow(props) {
  // This is moved into a useEffect to make sure SSR doesn't barf
  const [queryParams, setQueryParams] = useState({
    utm_source: "organic",
    utm_campaign: 0,
    utm_keyword: 0,
  });

  const { slides, title } = props.data.slideshowsJson;

  const [slideShowActivated, setSlideShowActivated] = useState(false);

  const [slideNumber, setSlide] = useState(0);

  const { location } = props;

  const slide = slides[slideNumber];

  const pageView = (e) => {
    console.log("PAGE VIEW");
    window.scroll(0, 0);
    // Pageview track to event service
    setSlide(parseInt(e.target.value), 10);
  };

  useEffect(() => {
    setQueryParams(parseQueryString());
  }, []);

  useEffect(() => {
    setSlideShowActivated(
      window.location.search.match(/utm_source=revcontent/i) != null &&
        window.location.search.match(/utm_campaign=\d{4,9}/i) != null
    );
  }, [queryParams]);

  return (
    <Layout location={location}>
      <Box className="blog-content">
        <Heading mt={2} color="blue.800">
          {title}
        </Heading>

        {slideShowActivated ? (
          <div>
            <Slide slide={slide} />
            <Button
              value={slideNumber + 1}
              width={"100%"}
              size="lg"
              colorScheme="blue"
              mb={4}
              onClick={pageView}
            >
              {slideNumber === 0 ? "Start Slideshow" : "Next Slide"}
            </Button>
          </div>
        ) : (
          slides.map((slide, i) => <Slide key={i} slide={slide} />)
        )}

        <RevcontentAd containerId="rc_under_article" widgetId={244906} />
      </Box>
    </Layout>
  );
}

export default Slideshow;

export const pageQuery = graphql`
  query ($slug: String!) {
    slideshowsJson(slug: { eq: $slug }) {
      title
      slug
      slides {
        body
        imageTitle
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
