import * as React from "react";
import { Heading, Box } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const Slide = ({ slide }) => {
  return (
    <div>
      {slide.imageTitle && (
        <Heading mt={2} as="h4" size={"md"}>
          {slide.imageTitle}
        </Heading>
      )}

      <Box mb={2} mt={2}>
        <GatsbyImage image={getImage(slide.image)} alt="" />
      </Box>

      <Box mt={2} mb={2}>
        <div dangerouslySetInnerHTML={{ __html: slide.body }}></div>
      </Box>
    </div>
  );
};

export default Slide;
