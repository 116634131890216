import React, { useEffect, useRef, useState } from "react";
const Revcontent = (props) => {
  const { widgetId, containerId } = props;
  const hasMounted = true; //todo: this was failing
  useEffect(() => {
    fetch(`https://next.revcontent.com/api/delivery?w=${widgetId}`, {
      headers: {
        accept: "text/html",
      },
    })
      .then((response) => {
        return response.text();
      })
      .then((body) => {
        const element = document.createRange().createContextualFragment(body);
        widgetRef.current.appendChild(element);
      });
  }, [widgetId, hasMounted]);

  const widgetRef = useRef(null);

  const rcStyle = `
    #rc_under_article .rc-brand-wrapper { display: none; }
    #rc_under_article .rc-brand-wrapper h3 { font-size: 1.2em !important; }
    #rc_under_article .rc-widget-header { padding: 0px !important; } 
    #rc_under_article .rc-feed-row .rc-sponsored { margin: 5px; width: 100% !important; }
    #rc_under_article .rc-feed-row { margin: 0px -5px 0px -5px !important;}
  `;

  return (
    <div id={containerId} key={hasMounted}>
      <style>{rcStyle}</style>
      <div ref={widgetRef}></div>
    </div>
  );
};

export default Revcontent;
