import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Box, Text } from "@chakra-ui/react";
import Footer from "../components/footer";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  const layoutData = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          description
          siteUrl
          title
          logo
        }
      }
      gitCommit(latest: { eq: true }) {
        hash
        date(formatString: "YYYY-MM-DD hh:mm:ss")
      }
    }
  `);

  const siteData = layoutData.site.siteMetadata;
  const { hash, date } = layoutData.gitCommit;

  const logo = () => {
    switch (siteData.title) {
      case "brovidly":
        return (
          <StaticImage
            height={55}
            src="../../static/logos/brovidly.png"
            alt="Brovidly bro"
          />
        );
      case "frightspark":
        return (
          <StaticImage
            height={55}
            src="../../static/logos/frightspark.png"
            alt="Omg so scared"
          />
        );
      case "strangehustle":
        return (
          <StaticImage
            height={55}
            src="../../static/logos/strangehustle.png"
            alt="I make muny"
          />
        );
      case "shrugzilla":
        return (
          <StaticImage
            height={55}
            src="../../static/logos/shrugzilla.png"
            alt="Rawr"
          />
        );

      default:
        return (
          <StaticImage
            height={55}
            src="../../static/logos/brovidly.png"
            alt="Brovidly bro"
          />
        );
    }
  };

  return (
    <Container mt={2} maxW="container.lg" data-is-root-path={isRootPath}>
      {logo()}
      <Container p={0} maxW="container.lg" display={{ md: "flex" }}>
        {children}
        <Box
          flexShrink={0}
          w={{ lg: "320px" }}
          display={{ base: "none", lg: "block" }}
          textAlign="center"
          p={5}
        >
          <Text textAlign="center">Advertisement</Text>
          <StaticImage
            src="../../static/ads/300x250/clothes.png"
            width={300}
            height={250}
            alt=""
          />
          <Text textAlign="center">Advertisement</Text>
          <StaticImage
            src="../../static/ads/300x250/nyt.png"
            width={300}
            height={250}
            alt=""
          />
        </Box>
      </Container>
      <Footer site={siteData}/>

        <div>{hash}</div>
        <div>{date}</div>
        <div>Sah</div>

    </Container>
  );
};

export default Layout;
